<template>
  <v-row class="fill-height">
    <v-col>
      <v-card>
        <v-card-title>
          <!--<v-icon
            @click="pushRouteToView('categories')"
            class="mr-4"
            style="cursor: pointer"
          >
            mdi-arrow-left
          </v-icon>-->
          <v-btn
              small
              @click="pushRouteToView('categories')"
              class="mr-4 btn-back"
            >
              <v-icon> mdi-arrow-left </v-icon>
              Voltar
            </v-btn>
          <v-icon class="mr-2">mdi-format-list-bulleted-square</v-icon>
          Dados da Categoria
          <v-spacer></v-spacer>
          <v-btn outlined class="btn-del"
                 @click="dialogDeleteCategory=true">
            <v-icon left>mdi-delete</v-icon>
            Excluir
          </v-btn>
          <v-btn outlined
                 :class="`${category.active? 'btn-inactive': 'btn-active'} mx-2`"
                 @click="dialogActiveInactive=true">
            <span v-if="category.active"><v-icon left>mdi-diameter-variant</v-icon> Inativar</span><span v-else><v-icon
            left>mdi-checkbox-marked-circle-outline</v-icon> Ativar</span>
          </v-btn>
          <v-btn
            color="#3A33E8"
            dark
            :to="{ name: 'categoriesEdit', params: { id: category.id } }"
          >
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-data-visualization">
          <v-row>
            <v-col  lg="5" md="5" sm="12" xl="5"><b><v-icon>mdi-format-align-justify </v-icon> Nome</b> {{ category.name }}</v-col>
          </v-row>
          <v-row>
            <v-col  lg="5" md="5" sm="12" xl="5">
              <b><v-icon>mdi-currency-usd </v-icon> Valor:</b> {{ category.value | money }}
            </v-col>          
          </v-row>
          <v-row>
            <v-col  lg="5" md="5" sm="12" xl="5">
              <b>Status:</b> <span v-if="category.active">Ativo</span><span v-else>Inativo</span>
            </v-col>
          </v-row>
          
        </v-card-text>
        <confirm-dialog
          :dialog="dialogActiveInactive"
          :title="`${category.active? 'Inativar ': 'Ativar '} ${category.name}`"
          body="Tem certeza que desenha realizar essa ação?"
          @dialog-event="inactiveActive">
        </confirm-dialog>
        <confirm-dialog
          :dialog="dialogDeleteCategory"
          :title="`Excluir  ${category.name}`"
          :body="`Tem certeza que deseja excluir o produto ${category.name} ?`"
          @dialog-event="deleteCategory">
        </confirm-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {CATEGORY_GET, CATEGORY_ACTIVE, CATEGORY_INACTIVE, CATEGORY_DELETE} from "@/store/actions/categories.type";
import {CATEGORY_RESET_STATE, SET_CATEGORY_ACTIVE} from "@/store/mutations/categories.type";
import ConfirmDialog from "@/components/ConfirmDialog";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";

export default {
  name: "employee",
  components: {
    ConfirmDialog,
  },

  data: () => ({
    items: "",
    dialogActiveInactive: false,
    dialogDeleteCategory: false,
  }),

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(CATEGORY_RESET_STATE);
    await store.commit(CATEGORY_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(CATEGORY_RESET_STATE),
      store.dispatch(CATEGORY_GET, to.params.id),
    ]).then(() => {
      next();
    });
  },

  methods: {
    pushRouteToView(route) {
      this.$router.push({name: route});
    },
    deleteCategory(status) {
      this.dialogDeleteCategory = false
      if (!status) {
        return
      }
      this.$store.commit(CHANGE_PROGRESS, true)
      this.$store
        .dispatch(CATEGORY_DELETE)
        .then(() => {

          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "success";
          this.snackbar.text = "Excluído com sucesso";

          this.$router.push({
            name: "categories",
          });
        })
        .catch(({response}) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "error";
          this.snackbar.text = "Erro ao excluir.";
          // console.log(response.data);
          if (typeof response.data.message !== "undefined") {
            this.snackbar.text = response.data.message;
          }
        });
    },
    inactiveActive(status) {
      this.dialogActiveInactive = false
      if (!status) {
        return
      }
      let action = this.category.active ? CATEGORY_INACTIVE : CATEGORY_ACTIVE;
      this.$store.commit(CHANGE_PROGRESS, true)

      this.$store
        .dispatch(action, this.category.id)
        .then(() => {

          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "success";
          this.snackbar.text = "Alterado com sucesso";
          this.$store.commit(SET_CATEGORY_ACTIVE, !this.category.active)
        })
        .catch(({response}) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "error";
          this.snackbar.text = "Erro ao alterar.";
          // console.log(response.data);
          if (typeof response.data.message !== "undefined") {
            this.snackbar.text = response.data.message;
          }
        });
    },

  },
  
  computed: {
    ...mapGetters(["category", "checkUsers", "snackbar"]),
  },
};
</script>
